.char-page-container {
    position: absolute; bottom: 0; height: 100%;
    display: flex;
    /* justify-content: center;  */
    align-items: center;
}

.c-p-inner-container {
    display: inline-flex; flex-direction: column;
    justify-content: space-between; align-items: center;
    height: 75%; width: 95%; 
    /* margin: 5% 0; */
}
.c-p-title-container {
    display: flex; justify-content: center; align-items: center;
    height: 10%; width: 95%;
     font-weight: 700; text-transform: uppercase;
}
/* Character Container */
.c-p-character-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    width: 95%; height: 90%;
}
.c-p-c-character {
    position: relative;
    display: flex; justify-content: center; align-items: center;
    height: 50%; width: 65%;
}

.cpcc-character-image {
    display: flex; justify-content: center; align-items: center;
    position: relative;
    width: 100%; height: 100%;
    max-width: 247px;
    /*max-height: 230px;*/
}
/*.cpcc-character-image img{height: 100%;}*/

.cpcc-selected, .cpcc-unselected {
    position: absolute; display: block;
    /* max-width: 247px; max-height: 230px; */
    width: 100%;
    /*height: 100%;*/
}

/* End of Character Container */
@media only screen and (max-width: 480px) {
    .class-small .c-p-title-container {
        font-size: 18px;
    }
    .class-medium .c-p-title-container {
        font-size: 20px;
    }
    .class-large .c-p-title-container {
        font-size: 22px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
    .class-small .c-p-title-container {
        font-size: 20px;
    }
    .class-medium .c-p-title-container {
        font-size: 22px;
    }
    .class-large .c-p-title-container {
        font-size: 24px;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .class-small .c-p-title-container {
        font-size: 22px;
    }
    .class-medium .c-p-title-container {
        font-size: 24px;
    }
    .class-large .c-p-title-container {
        font-size: 26px;
    }
}