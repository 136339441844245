.ab-container {
    display: flex; justify-content: center; align-items: center;
}
.ab-inner-container {
    display: flex; flex-direction: column;
    justify-content: space-between; align-items: center;
    height: 80%; width: 95%; margin-bottom: 20%;
}
.ab-image-container {
    width: 100%;
    margin-top: 20%;
    text-align: center;
}
.ab-image-container img {
    width: 60%;
}
.char-container {
    display: flex; flex-direction: row;
    align-items: center; justify-content: space-between;
    height: 20%; margin-bottom: 0%;
    font-size: 22px; font-weight: 400; text-align: center;
}
.ab-char-container {
    overflow: visible;
}
.char-flip {
    transform: rotateY(180deg);
}
.ab-char-margin-l {margin-left: 15px;}