
.spinGameApp {
	display: flex;
	justify-content: center;
    flex-direction: column;
    align-items: center;
	height: 100%;
	width: 100%;
	color:#fff;
}

.spinTitle {
	font-family: Verlag;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	/* line-height: 26px; */
	color: #000000;
	top: 0;
	position: relative;
}
.spinText {
	font-family: Verlag;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	/* line-height: 132.99%; */
	text-align: center;
	color: #000000;
	bottom: 15%;
    position: relative;
}


#spin, #reset {
	font-size: 22px;
	font-weight: 700;
	text-decoration: none;
	color: white;
	background: linear-gradient(
			180deg
			, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
	border-radius: 30px;
	bottom: 4%;
	padding:1.25rem 3rem;
	position: absolute;
}

#spin:focus, #reset:focus {
	outline:none;
}


#readout {
	position: absolute;
	bottom: 7vh;
	left: 35vw;
}


#selector {
	position: absolute;
	z-index: 3;
	top: 41%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#result{
	font-size:50px;
	color: rgb(0, 180, 120);
}

input {
	font-size: 16px;
	border:none;
	border-bottom: 1px solid black
}