.back-btn{
  width: 100%;
  position: absolute;
  top: 0;
  left:0
}

.back-btn button, .back-btn img {
  height: 100%;
  display:flex;
}
.back-btn img {
  width:100%;
}
.back-btn button {
  background: none; border: none;
}

.back-btn.back-exit-btn {
	width:80px;
}

.back-btn.back-exit-btn img {
	width:100%;
}

@media only screen and (max-width: 480px) {
  .back-btn img {
    width:70%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .back-btn img {
    width:80%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .back-btn img {
    width:95%;
  }
}
/* @media only screen and (max-width: 1200px) {
  .back-btn img {
    width:75%;
  }
} */