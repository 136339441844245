.sp-page-container {
    position: absolute; bottom: 0; height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.c-p-inner-container {
    display: inline-flex; flex-direction: column;
    justify-content: space-between; align-items: center;
    height: 75%; width: 95%;
    /* margin: 5% 0; */
}
.c-p-title-container {
    display: flex; justify-content: center; align-items: center;
    height: 10%; width: 95%;
    font-size: 24px; font-weight: 700; text-transform: uppercase;
}

/* sp Container */
.c-p-sp-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    width: 95%; height: 90%;
}
.c-p-c-sp {
    position: relative;
    display: flex; justify-content: center; align-items: center;
    height: 50%; width: 65%;
}

.cpcc-sp-image {
    display: flex; justify-content: center; align-items: center;
    position: relative;
    width: 100%; height: 100%;
    /* max-width: 247px; max-height: 230px; */
}

/*.cpcc-sp-image img{height: 90%;}*/

.cpcc-selected, .cpcc-unselected {
    position: absolute; display: block;
    max-width: 247px;
    /*max-height: 230px;*/
    /*width: auto; height: auto;*/
}

/* End of sp Container */
.starting-point-desc {
    text-align: center;
}
.starting-point-desc h3{
    font-style: normal;
    font-weight: 700;
    /* line-height: 24px; */
    letter-spacing: 0px;
    text-align: center;
}
.starting-point-desc p{
    font-style: normal;
    font-weight: 400;
    /* line-height: 24px; */
    letter-spacing: 0px;
    text-align: center;
}
@media screen and  ( max-height: 600px ) {
    .cpcc-selected, .cpcc-unselected {
        width:75%
    }
}

@media screen and  ( min-height: 601px )and  ( max-height: 750px ) {
    .cpcc-selected, .cpcc-unselected {
        width:85%
    }
}
@media only screen and (max-width: 480px) {
    .class-small .starting-point-desc h3, .class-small .starting-point-desc p {
        font-size: 16px;
    }
    .class-medium .starting-point-desc h3, .class-medium .starting-point-desc p {
        font-size: 18px;
    }
    .class-large .starting-point-desc h3, .class-large .starting-point-desc p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
    .class-small .starting-point-desc h3, .class-small .starting-point-desc p {
        font-size: 18px;
    }
    .class-medium .starting-point-desc h3, .class-medium .starting-point-desc p {
        font-size: 20px;
    }
    .class-large .starting-point-desc h3, .class-large .starting-point-desc p {
        font-size: 22px;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .class-small .starting-point-desc h3, .class-small .starting-point-desc p {
        font-size: 20px;
    }
    .class-medium .starting-point-desc h3, .class-medium .starting-point-desc p {
        font-size: 22px;
    }
    .class-large .starting-point-desc h3, .class-large .starting-point-desc p {
        font-size: 24px;
    }
}