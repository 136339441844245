.location-btn button {
	width           : 42px;
	height          : 42px;
	border-radius   : 50px;
	position        : fixed;
	top             : 120px;
	float           : right;
	margin-right    : 20px;
	background-color: #fff;
	z-index         : 1;
	right           : -15px
}

@media only screen and (max-width: 1024px) and (min-width: 710px) {
	.location-btn button {
		width       : 62px;
		height      : 62px;
		margin-right: 8%;
	}
}

@media only screen and (min-width: 460px) {
	.location-btn button {
		top: 200px;
	}
}

@media only screen and (min-width: 630px) {
	.location-btn button {
		top: 250px;
	}
}