/* .guide-container {

}

.inner-guide-container h4{
    text-transform: initial;
}

.guide-subtitle {
    margin: 1rem 0 2rem;
}

.guide-interaction-container {
    display: flex;
    margin: 2rem 1rem 1rem;
}
.guide-interaction-container div{
    width: 20rem; height: 7rem;
    margin-left: 3rem;
    text-align: center;
}

.guide-image-container{
    display: flex; justify-content: center; align-items: center;
}
.guide-image-container img {
}

.guide-calibrate-img {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 12px 0 rgba(0, 0, 0, 0.29);
    background: none; border-radius: 100px;
} */
 .guide-interaction-container {
    display: flex;
    margin: 2rem 2rem 2rem 2rem;


}
.guide-interaction-container div{
    width: 20rem;
    margin-left: 3rem;
    text-align: left;
}

/* .guide-calibrate-img {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 12px 0 rgba(0, 0, 0, 0.29);
    background: none; border-radius: 100px;
} */
/* .inner-guide-container {
    max-width: 75vw;height: fit-content;
    border-radius: 8px; margin: 3rem 0; padding: 3rem;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    text-align: center;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 12px 0 rgba(0, 0, 0, 0.29);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 12px 0 rgba(0, 0, 0, 0.29);
} */
.help-inner-container
{
	overflow: scroll;
	margin-top: 60px;
}
.inner-guide-container .help-icon {
	width: 78px;
	height: 78px;
	border-radius: 50px;
	box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.3), 0 0px 2px 0 rgba(0, 0, 0, 0.29);
	margin: 0
}

.inner-guide-container .help-icon img {
	position: relative;
}
.inner-guide-container .navigation img {
	top: 15%;
	left: 25%;
}

.inner-guide-container .exit img {
	top: 13%;
	left: 15%
}
