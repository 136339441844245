.accessibility-inner-container {
    width: 90%; margin: auto;
}

/* Title Section */
.accessibility-title-container {
    display: flex; justify-content: center; align-items: center;
    width: 100%; margin-bottom: 0rem;
	margin-top:1rem;
}

/* Font Size Section */
.a-font-size-container {
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;
    width: 100%; margin: 50px 0;
}
.a-font-size-container h3 {
    font-size: 20px;
    /* line-height: 24px; */
    font-weight: 400;
}
.font-size-inner-container {
    display: flex; justify-content: space-between;
    width: 70%;
}

.font-btn {
    background: none; border: none;
}
/* End of Font Size Section */

/* Audio Guidance Container Section */
.audio-container {
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    width: 100%; margin-bottom: 50px;
}
.audio-container h3 {
    font-size: 20px;
    /* line-height: 24px; */
    font-weight: 400;
}
.sound-container {
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;
    width: 100%;
}
/* switch */
.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        /* line-height: 34px; */
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
      }
.toggle-switch-inner:before {
        content: "";
        text-transform: uppercase;
        padding-left: 10px;
        background-color: rgba(0, 87, 159, 0.94);
        color: #fff;
      }
.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
      display: block;
      width: 24px;
      margin: 5px;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 40px;
      border: 0 solid #bbb;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
    .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
        margin-left: 0;
      }
    .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
        right: 0px;
      }

    @media only screen and (max-width: 480px) {
        .class-small .accessibility-title-container h2 {
            font-size: 16px;
        }
        .class-medium  .accessibility-title-container h2{
            font-size: 18px;
        }
        .class-large  .accessibility-title-container h2{
            font-size: 20px;
        }

        .class-small .a-font-size-container h3{
            font-size: 14px;
        }
        .class-medium .a-font-size-container h3{
            font-size: 16px;
        }
        .class-large .a-font-size-container h3{
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 768px) and (min-width: 481px) {
        .class-small .accessibility-title-container h2 {
            font-size: 18px;
        }
        .class-medium  .accessibility-title-container h2{
            font-size: 20px;
        }
        .class-large  .accessibility-title-container h2{
            font-size: 22px;
        }

        .class-small .a-font-size-container h3{
            font-size: 16px;
        }
        .class-medium .a-font-size-container h3{
            font-size: 18px;
        }
        .class-large .a-font-size-container h3{
            font-size: 20px;
        }
      }
    @media only screen and (max-width: 1024px) and (min-width: 769px) {
        .class-small .accessibility-title-container h2 {
            font-size: 20px;
        }
        .class-medium  .accessibility-title-container h2{
            font-size: 22px;
        }
        .class-large  .accessibility-title-container h2{
            font-size: 24px;
        }

        .class-small .a-font-size-container h3{
            font-size: 18px;
        }
        .class-medium .a-font-size-container h3{
            font-size: 20px;
        }
        .class-large .a-font-size-container h3{
            font-size: 22px;
        }
      }