
/* Question Component */
.question-component {
    display: flex; align-items: center;
    width: 90%; margin: 5% auto;
    font-weight: bold;
}

.answer-container {
    display: flex; flex-wrap: wrap; position: relative;
    justify-content: center; align-items: center;
    width: 95%; margin: auto;
}

.answer-btn {
    height: 26vh; width: 45%;
    margin: 0.5rem; padding: 1rem;
    border: 1px solid #59D7FB; border-radius: 10px; background: none;
}

.answer-container-under-image .answer-btn  {
	max-height:15vh;
}

.hint-btn{
    display: flex; justify-content: center; align-items: center;
    position: absolute; transform: translate(-50%, -50%);
    left: 50%; top: 50%; width: 23%;
    border: none; background: none;
}
.hint-btn img {
    width: 100%;
}

.quiz-image {
	position:relative;
	height:20vh;
	text-align:center;
	margin:1em 0;
}
.quiz-image img{
	overflow: hidden;
	height:100%;
}
/* End of Question Component */

/* Scorebox Component*/
.question-count-container {
    width: fit-content;
    position: absolute; top: 3%; right: 5%;
    font-size: 18px;
}
/* End of Scorebox Component*/
.quiz-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-content: space-between;
}

.quiz-container h3{
    text-align: left;

    padding-left: 5vw;
    padding-bottom: 2rem;
}





.results-absolute {
    position: absolute;
	top:0;
	left: 0;
    width: 100vw; height: 100vh;
    background: linear-gradient(180deg, #005CA9 0%, #1488CA 78.13%);
    display: flex;
    align-items: center;
}

.results-container {
    /* height: 50%; */
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

.results-cont{
    text-align:center;
}

.results-text h3{
    padding: 0; margin: 0;
    display: flex;
    justify-content: center;
    font-weight: 900;
    font-size: 50px;
    color: #FFFFFF;
}
.results-text h4{
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 50px 0;
}
.results-text h5{
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
}
.quiz-return-btn{
    display: flex;
    justify-content: center; align-items: center;
    width: fit-content; height: fit-content;

    padding: 1.5rem 4.5rem;
    border-radius: 100px;

    background: linear-gradient(180deg, #B71918 0%, #E42313 100%);
    color: white;
    text-decoration: none;

    margin: auto;
}
.quiz-return-btn h4 {
    font-weight: bold;
}
/* Hint Popup Section */
.hint-popup-container {
    display: flex; justify-content: center; align-items: center;
    position: absolute; top: 0; left: 0; bottom: 0; right: 0;
    width: 100vw; height: 100vh;
    background: rgba(0,0,0,0.6); z-index: 99;
}
.h-p-box-container {
    display: flex; justify-content: center; align-items: center;
    width: 90%; height: auto;
    background: white; border-radius: 12px;
    height:60%;
    margin-bottom : 25%
}
.hpb-inner-container {
    width: 90%; height: 100%;
}
.quit-container {
    height : 35%;
}
.result-answer{
    height: 50%;
    padding: 7% 0
}
/** Close Button */
.hpb-close-button {
    display: flex; align-items: center;
    height: 10%; width: 100%;
}
.hpb-close-button button{
    height: 40%; background: none;
}
.hpb-close-button img{
    height: 100%;
}
/** End of Close Button */

/** Body Section */
.hpb-body-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    height: 80%; width: 100%; margin-bottom: 10%;
}

.hpdb-inner-container{
    display: flex; justify-content: center; align-items: center;
    width: 100%;
    flex-direction: column;
}

/*** Title Section */
.hpdb-title-container {
    /* height: 7.5%; */
     margin-bottom: 2.5%;
    font-weight: 700;
	/* line-height: 32px; */
    text-align: center;
}
/*** End of Title Section */

/*** Image Section */
.hpdb-image-container {
    position: relative; display: flex;
    justify-content: center; align-items: center;
    height: 50%; width: 100%;
    margin-top: 2.5%; margin-bottom: 2.5%;
}
.hpdbi-bubble {
    display: flex; justify-content: center; align-items: center;
    position: absolute; left: 0; top: 0; right: 0; bottom: 0;
}
.hpdbi-bubble img {
    width: 100%; max-width: 250px; max-height: 100%;
}

.hpdbi-image {
    display: flex; justify-content: center; align-items: center;
    position: absolute; left: 0; right: 0; top: 0; bottom: 0;
}
.hpdbi-image img { max-width: 100%; max-height: 100%; }
/*** End of Image Section */

/*** Description Section */
.hpdb-description-container {
    text-align: center;
    height: 20%; margin-top: 2.5%; margin-bottom: 2.5%;
    font-weight: 400;
	/* line-height: 29px; */
}
/*** End of Description Section */

/*** Button Section */
.hpdb-button-container {
    margin-top: 2.5%;
}
.hpdb-button {
    display: flex; justify-content: center; align-items: center;
    /* height: 90%; width: 50%; */
    /* max-height: 60px; max-width: 132px; */
    min-width:50%;
    min-height:50px;
    border-radius: 32px; border: none; color: white;
     line-height: 26px; font-weight: 700;
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
}
.alert{
background: linear-gradient(180deg, #B71918 100%, #E42313 100%);
}
button.next-button {
    /* float: right; */
    background-color: white;
}
.container-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.quit-container {
flex-direction:row;
justify-content: space-evenly;
}

.quit-btn{
    min-width:35%
}

.btn-text {
    padding : 20px 0;
    text-align : center;
    font-weight: 400;
}
/*** End of Button Section */

/** End of Body Section */

/* End of Hint Popup Section */
/* confetti */
.confetti-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
    z-index: 20;
    animation: moveConfetti 2s;
    animation-direction: normal;
}
@keyframes moveConfetti {
    0%   {top: -50%; opacity:0;}
    5% {opacity:1}
    100% {top: 0%;}
}
.results-cont {
    z-index: 100;
}
@media only screen and (max-width: 361px) {
    .quiz-image {
        margin : 0;
        height: 20vh
    }
}

@media only screen and (max-width: 480px) {
    .class-small .question-component ,.class-small .hpdb-title-container, .class-small .hpdb-button, .class-small .btn-text{
        font-size: 16px;
    }
    .class-medium .question-component ,.class-medium .hpdb-title-container, .class-medium .hpdb-button, .class-medium .btn-text{
        font-size: 18px;
    }
    .class-large .question-component,.class-large .hpdb-title-container,  .class-large .hpdb-button , .class-large .btn-text{
        font-size: 20px;
    }

    .class-small .answer-btn, .class-small .hpdb-description-container {
        font-size: 14px;
    }
    .class-medium .answer-btn , .class-medium .hpdb-description-container{
        font-size: 16px;
    }
    .class-large .answer-btn , .class-large .hpdb-description-container{
        font-size: 18px;
    }

    .hint-btn{
        max-width: 85px;
    }

  }

  @media only screen and (max-width: 768px) and (min-width: 481px) {
    .class-small .question-component ,.class-small .hpdb-title-container,  .class-small .hpdb-button, .class-small .btn-text{
        font-size: 18px;
    }
    .class-medium .question-component ,.class-medium .hpdb-title-container , .class-medium .hpdb-button, .class-medium .btn-text{
        font-size: 20px;
    }
    .class-large .question-component ,.class-large .hpdb-title-container ,  .class-large .hpdb-button, .class-large .btn-text{
        font-size: 22px;
    }

    .class-small .answer-btn ,.class-small .hpdb-description-container{
        font-size: 16px;
    }
    .class-medium .answer-btn , .class-medium .hpdb-description-container{
        font-size: 18px;
    }
    .class-large .answer-btn, .class-large .hpdb-description-container {
        font-size: 20px;
    }

    .hint-btn{
        max-width: 95px;
    }
  }
  @media only screen and (max-width: 1024px) and (min-width: 769px) {
    .class-small .question-component ,.class-small .hpdb-title-container , .class-small .hpdb-button, .class-small .btn-text{
        font-size: 20px;
    }
    .class-medium .question-component ,.class-medium .hpdb-title-container , .class-medium .hpdb-button, .class-medium .btn-text{
        font-size: 22px;
    }
    .class-large .question-component ,.class-large .hpdb-title-container ,  .class-large .hpdb-button, .class-large .btn-text{
        font-size: 24px;
    }

    .class-small .answer-btn ,.class-small .hpdb-description-container{
        font-size: 18px;
    }
    .class-medium .answer-btn , .class-medium .hpdb-description-container{
        font-size: 20px;
    }
    .class-large .answer-btn , .class-large .hpdb-description-container{
        font-size: 22px;
    }
    .hint-btn{
        max-width: 105px;
    }
  }