
.certificate-container {
    display: flex; justify-content: center; align-items: center;
    width: 100%;
    flex-direction:column;
    height: 100%;
    background-color: #FCFEFF ;
}
/* Character Image Section */
.image-container {
    display: flex; align-items: center;
    /* width: 100%; height: 20%; */
    flex-direction: column;
}
.image-container img {
    /* height: 100%; max-height: 116px;
    margin-left: 18%; margin-right: 18%; */
}
/* End of Character Image Section */

/* Button Container */
.button-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    height: 15%; width: 100%;
}
.button {
    display: flex; justify-content: center; align-items: center;
    height: 45%; border-radius: 30px;
    font-size: 22px; font-weight: 700;
	/*line-height: 26px;*/
	color: white;
}

.lb-save-button {
    width: 60%;
     /* margin-top: 2.5%; */
    max-width: 130px; max-height: 60px;
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
	z-index: 21;
}
/* End of Button Container */
.certificate-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    height: 70%;
    background: white;
    border-radius: 12px;
    width:90%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.certificate-title {
    font-size: 22px;
    /* line-height: 133%; */
    text-align: center;
    font-weight: 400;
    color: #01080D;

}
.boldtext {
    font-weight: 900;
    font-size: 30px;
}
.certificate-description {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.certificate-desc {
    text-align: center;
    color: #01080D;
    font-weight: normal;
    font-size: 20px;
    /* line-height: 24px; */

    margin-bottom: 6%;
}

.space {
    margin-top:5%;
}
.lb-play-button {
	z-index: 21;
}