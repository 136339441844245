/*.div-responsive {*/
/*	display: inline-block;*/
/*	width: 100%;*/
/*	max-width: 100%;*/
/*	position: relative;*/
/*	!*height:100%;*!*/
/*	!*transform-origin: 0 0;*!*/
/*	!*transition-duration: 3s*!*/
/*}*/
/*.img-responsive {*/
/*	height: auto;*/
/*	width: auto;*/
/*	max-width: 100%;*/
/*	!*max-height:100%;*!*/
/*}*/
/*.markers {*/
/*	position: absolute; top: 0; right: 0; bottom: 0; left: 0;*/
/*}*/
/*.marker {*/
/*	position: absolute;*/
/*	width: 80px;*/
/*	height: 80px;*/
/*	z-index: 1;*/
/*!*	-moz-transform: translateX(-50%) translateY(-50%);*!*/
/*!*	-ms-transform: translateX(-50%) translateY(-50%);*!*/
/*!*	-o-transform: translateX(-50%) translateY(-50%);*!*/
/*!*	transform: translateX(-50%) translateY(-50%);*!*/
/*}*/
/*.middle{*/
/*	position:absolute;*/
/*	top:0;*/
/*	bottom:0;*/
/*	right: 0;*/
/*	left: 0;*/
/*	margin: auto;*/
/*}*/

/*.testdiv1 {*/
/*	width:200%; position:relative; overflow:scroll; transform:scale(2) translate(50px, 50px)*/
/*}*/
/*.testimg1{*/
/*	width:200%; max-width:1000%;*/
/*}*/
/*.testpointer {*/
/*	width: 25px;*/
/*	height: 25px;*/
/*	position: absolute;*/
/*	top: 0;*/
/*	left: 0;*/
/*}*/

.mapAnimation {
	animation: moveMap 3s 1;
    animation-direction: normal;
}
@keyframes moveMap {
    /* 0%   {width: 5%; transform:rotateX(50%);}
    10%   {width: 10%; transform:rotateX(20%)}
    100% {width: 100%;} */
}
@media screen and (min-width: 799px) {

}