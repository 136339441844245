.modal {
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}
.popup-container {
    /* position: absolute;
    top: 51%;
    left: 3%;
    transform: translateY(-50%); */
    width: 98%;
    /* height: 95%; */
    /*padding: 10px;*/
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}
.time-container {
    height:100%
}
.popup-inner-container {
    width: 81%;
    height: 90%;
    border-radius: 10px;
    padding: 25px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.popup-content {
    font-size: 22px;
    font-weight: 700;
    /* line-height: 26px; */
    /*text-transform: uppercase;*/
    text-align: center;
    margin-bottom: 50px;
}
.close-button {
    position: absolute;
    top: 25px;
    left: 25px;
}
.a-font-size-container h3 {
    white-space: nowrap;
    margin-right: 50px;
}

.era {
    width: 100%;
    border-radius: 10px;
    padding: 8px 15px;
    margin-bottom: 5px;
    color: #000;
}

.era h3{
    font-weight: 700;
}

.currentZone {
    background-color: #fff;
    border: solid 1px #000;
}
.location-pin {
    justify-content: center;
}
.location-pin .map-nav__title {
    width: 55%;
    float: left;
}

.location-pin  .map-nav__img {
    width: 45%;
    float: left;
}

.location-pin  .map-nav__img img{
    width: 100%;
}

.location-pin .era-1 {
    background-color: #6EB7AE;
    padding: 37px 18px 38px 18px!important;
    margin-bottom: 12px;
}

.location-pin .concorde-1 {
    border: solid 2px #C6CFD4;
    padding: 37px 18px 38px 18px!important;
}

.location-pin .popup-container {
    height: 70%;
}

.concorde-hangar .map-nav__title{
    width: 55%;
    float: left;
}

.concorde-hangar .map-nav__img{
    width: 45%;
    float: left;
}
.concorde-hangar .map-nav__img img{
    width: 100%;
}

.location-close {
    height: 40%;
}
.location-popup {
    width: 100%;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.location-popup a {
	text-align: left;
}
.location-popup .popup-inner-container{
   padding: 0 25px;
   width:80%;
   margin-top: 5px;
   height: 100%;
   border-radius: 10px;
   background-color: white;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.location-popup .popup-content {
   margin-bottom:2%;
}
.location-popup .era {
padding:1% 15px}

.location-popup .hpb-close-button{
   height:25px
}
.location-popup .location-close {
    height: 80%;
}

.ab-nav{
	top: 30px!important;
	height: 120vh!important;
}

@media only screen and (max-width: 480px) {
    .class-small .popup-content, .class-small .era h3, .class-small .era p{
        font-size: 18px;
    }
    .class-medium .popup-content, .class-medium .era h3, .class-medium .era p{
        font-size: 20px;
    }
    .class-large .popup-content , .class-large .era h3, .class-large .era p{
        font-size: 22px;
    }
  }

@media only screen and (max-width: 768px) and (min-width: 481px) {
    .class-small .popup-content ,.class-small .era h3, .class-small .era p{
        font-size: 20px;
    }
    .class-medium .popup-content, .class-medium .era h3, .class-medium .era p{
        font-size: 22px;
    }
    .class-large .popup-content , .class-large .era h3, .class-large .era p{
        font-size: 24px;
    }
  }
@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .class-small .popup-content, .class-small .era h3, .class-small .era p{
        font-size: 22px;
    }
    .class-medium .popup-content, .class-medium .era h3, .class-medium .era p{
        font-size: 24px;
    }
    .class-large .popup-content, .class-large .era h3, .class-large .era p{
        font-size: 26px;
    }
  }
