.ready-container {
    display: flex; justify-content: center; align-items: center;
}

.ready-inner-container {
    display: flex; flex-direction: column;
    justify-content: space-between; align-items: center;
    height: 80%; width: 95%; margin-bottom: 20%;
}

/* Title Container */
.r-title-container {
    display: flex; justify-content: center; align-items: center;
    height: 10%; width: 95%; margin-top: 5%;
    font-weight: 700; text-transform: uppercase;
}
/* End of Title Container */

/* Description Container */
.r-description-container {
    display: flex; align-items: center; justify-content: center;
    width: 95%; height: 20%; margin-bottom: 5%;
    font-weight: 400; text-align: center;
}
/* End of Description Container */

/* Image Section */
.r-image-container {
    display: flex; justify-content: flex-start; align-items: center;
    width: 105%; height: 30%;
}
.r-image-container img{
    width: 85%;
}
/* End of Image Section */

/* Continue Button */
.r-continue-container {
    display: flex; justify-content: center; align-items: center;
    height: 25%; width: 95%;
}
.r-continue { width: 70%; }
/* End of Continue Button */

.r-image-animated {
    position: relative;
    animation: movePlane 3s infinite;
    animation-direction: normal;
}
@keyframes movePlane {
    0%   {left: -25%;}
    100% {left: 125%;}
}
@media only screen and (max-width: 480px) {
    .class-small .r-title-container,.class-small .basic-btn h3 {
        font-size: 18px;
    }
    .class-medium .r-title-container ,.class-medium .basic-btn h3 {
        font-size: 20px;
    }
    .class-large .r-title-container,.class-large .basic-btn h3  {
        font-size: 22px;
    }
    .class-small .r-description-container {
        font-size: 16px;
    }
    .class-medium .r-description-container {
        font-size: 18px;
    }
    .class-large .r-description-container {
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
    .class-small .r-title-container,.class-small .basic-btn h3 {
        font-size: 20px;
    }
    .class-medium .r-title-container, .class-medium .basic-btn h3{
        font-size: 22px;
    }
    .class-large .r-title-container , .class-large .basic-btn h3{
        font-size: 24px;
    }
    .class-small .r-description-container {
        font-size: 18px;
    }
    .class-medium .r-description-container{
        font-size: 20px;
    }
    .class-large .r-description-container {
        font-size: 22px;
    }

}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .class-small .r-title-container,.class-small .basic-btn h3 {
        font-size: 22px;
    }
    .class-medium .r-title-container , .class-medium .basic-btn h3{
        font-size: 24px;
    }
    .class-large .r-title-container, .class-large .basic-btn h3 {
        font-size: 26px;
    }
    .class-small .r-description-container {
        font-size: 20px;
    }
    .class-medium .r-description-container {
        font-size: 22px;
    }
    .class-large .r-description-container {
        font-size: 24px;
    }
}