.p-info-container {
    position: absolute; bottom: 0; height: 100%;
    display: flex;
    /* justify-content: center;  */
    align-items: center;
    /* flex-direction: column; */
}

.player-inner-container {
    display: inline-flex; flex-direction: column;
    justify-content: space-between; align-items: center;
    height: 80%; width: 92.5%;
}
.player-inner-container span { color: red; margin-left: 0.5rem; }

/* Title Container */
.p-i-title-container {
    display: flex; justify-content: center; align-items: center;
    width: 100%; height: 10%;
    font-weight: 700; text-transform: uppercase;
	/* line-height: 26px; */
}

/* End of Title Container */

/* Form */
.p-i-form {
    width: 100%;
    height: 60%;
}

/* Form Section */
.p-i-input-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    height: 30%; width: 100%;
}
.p-i-input-container label {
    display: flex; align-items: center;
    width: 100%; height: 40%;
    font-size: 22px; font-weight: 400;
    margin-bottom: 5%;
}
.p-i-input-container input {
    display: flex; justify-content: center; align-items: center;
    width: 90%; height: 20px;
    font-size: 18px; font-weight: 400;
	/* line-height: 26px; */
}
.p-i-input-container input:focus-visible {
    outline-color: rgba(0, 87, 159, 0.94);
}
/* End of Username Section */

/**** Form Description Section */
.p-i-description-container {
    /*display: flex;*/
    align-items: center;
    margin-top: 40%;
    margin-bottom: 10%;
    font-weight: 400;
    /* line-height: 24px; */
}
/**** End of Description Section */

/* Accessibility Button */
.p-i-accessibility-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    height: 28%; width: 100%;
}

.p-i-a-description {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    font-size: 20px; font-weight: 400;
	/* line-height: 24px; */
    height: 35%; width: 100%;
}
.p-i-a-description div {
    width: 376px; height: 44px; background: red;
}

.p-i-a-button-container {
    display: flex; justify-content: center; align-items: center;
    height: 65%; width: 100%;
}
.p-i-a-button{
    display: flex; justify-content: center; align-items: center;
    width: 85%; height: 60%; max-height: 55px;
    border-radius: 27px;
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
}
.p-i-a-b-image {
    display: flex; justify-content: center; align-items: center;
    width: 15%; height: 100%;
}
.p-i-a-b-title {
    display: flex; justify-content: center; align-items: center;
    width: 70%; height: 100%;
    font-weight: 700; color: white;
	/* line-height: 26px; */
}
/* End of Accessibility Button */

@media only screen and (max-width: 480px) {
    .class-small .p-i-title-container ,.class-small .p-i-a-b-title{
        font-size: 18px;
    }
    .class-medium .p-i-title-container, .class-medium .p-i-a-b-title {
        font-size: 20px;
    }
    .class-large .p-i-title-container, .class-large .p-i-a-b-title {
        font-size: 22px;
    }
    .class-small .p-i-description-container, .class-small .p-i-a-description, .class-small label {
        font-size: 16px;

    }
    .class-medium .p-i-description-container, .class-medium .p-i-a-description,.class-medium label {
        font-size: 18px;
    }
    .class-large .p-i-description-container, .class-large .p-i-a-description, .class-large label {
        font-size: 20px;
    }
  }

@media only screen and (max-width: 768px) and (min-width: 481px) {
    .class-small .p-i-title-container, .class-small .p-i-a-b-title {
        font-size: 20px;
    }
    .class-medium .p-i-title-container, .class-medium .p-i-a-b-title {
        font-size: 22px;
    }
    .class-large .p-i-title-container, .class-large .p-i-a-b-title {
        font-size: 24px;
    }

    .class-small .p-i-description-container, .class-small .p-i-a-description , .class-small label {
        font-size: 18px;
    }
    .class-medium .p-i-description-container, .class-medium .p-i-a-description, .class-medium label {
        font-size: 20px;
    }
    .class-large .p-i-description-container,  .class-large .p-i-a-description, .class-large label  {
        font-size: 22px;
    }
  }
@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .class-small .p-i-title-container,.class-small .p-i-a-b-title{
        font-size: 22px;
    }
    .class-medium .p-i-title-container, .class-medium .p-i-a-b-title {
        font-size: 24px;
    }
    .class-large .p-i-title-container,  .class-large .p-i-a-b-title {
        font-size: 26px;
    }
    .class-small .p-i-description-container, .class-small .p-i-a-description , .class-small label {
        font-size: 20px;
    }
    .class-medium .p-i-description-container, .class-medium .p-i-a-description , .class-medium label{
        font-size: 22px;
    }
    .class-large .p-i-description-container, .class-large .p-i-a-description, .class-large label  {
        font-size: 24px;
    }
  }
