.exit-btn {
	width           : 42px;
	height          : 42px;
	border-radius   : 50px;
	margin          : 20px;
	background-color: #fff;
	z-index         : 1;
	position        : fixed;
	bottom          : -15px;
	right           : -15px;
}

.exit-description {
	margin: 10% 0 !important;
}

.end-btn {
	margin-top: 5%;
	background: linear-gradient(180deg, #B71918 0%, #E42313 100%) !important;
	width     : 80%
}

@media only screen and (max-width: 1024px) and (min-width: 710px) {
	.exit-btn {
		width : 62px;
		height: 62px;
		margin: 0 8% 8% 8%;

	}
}