.fact-screen-container {
	display        : flex;
	justify-content: space-between;
	align-items    : center;
	/* height      : 102%; */
	justify-content: center;
}

/* .fact-screen-container .next-button img {height: 120%;} */

.fact-inner-container {
	height         : 80vh;
	display        : flex;
	flex-direction : column;
	justify-content: center;
	align-items    : center;
	box-shadow     : 0 3px 10px rgb(0 0 0 / 20%);
	border-radius  : 12px;
	margin-bottom  : 5rem;
	width          : 90%;
}

.fact-image {
	display        : flex;
	justify-content: center;
	height         : 40%;
	overflow       : hidden;
	margin         : 10% 0;
}

.fact-image img {
	height   : 100%;
	/* needed for safari */
	max-width: 200vw;
}

.fact-description {
	max-width: 80vw;
	margin   : 1rem;
}

@media only screen and (max-width: 480px) {
	.class-small .fact-inner-container h2 {
		font-size: 16px;
	}

	.class-medium .fact-inner-container h2 {
		font-size: 18px;
	}

	.class-large .fact-inner-container h2 {
		font-size: 20px;
	}

	.class-small .fact-description p {
		font-size: 14px;
	}

	.class-medium .fact-description p {
		font-size: 16px;
	}

	.class-large .fact-description p {
		font-size: 18px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
	.class-small .fact-inner-container h2 {
		font-size: 18px;
	}

	.class-medium .fact-inner-container h2 {
		font-size: 20px;
	}

	.class-large .fact-inner-container h2 {
		font-size: 22px;
	}

	.class-small .fact-description p {
		font-size: 16px;
	}

	.class-medium .fact-description p {
		font-size: 18px;
	}

	.class-large .fact-description p {
		font-size: 20px;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
	.class-small .fact-inner-container h2 {
		font-size: 20px;
	}

	.class-medium .fact-inner-container h2 {
		font-size: 22px;
	}

	.class-large .fact-inner-container h2 {
		font-size: 24px;
	}

	.class-small .fact-description p {
		font-size: 18px;
	}

	.class-medium .fact-description p {
		font-size: 20px;
	}

	.class-large .fact-description p {
		font-size: 22px;
	}
}

/* confetti */
.results-img img {
	width: 112px;
}

.confetti-container {
	height             : 100%;
	width              : 100%;
	position           : absolute;
	top                : 0;
	left               : 0;
	z-index            : 20;
	animation          : moveConfetti 2s;
	animation-direction: normal;
}

@keyframes moveConfetti {
	0% {
		top    : -50%;
		opacity: 0;
	}

	5% {
		opacity: 1
	}

	100% {
		top: 0%;
	}
}

.results-cont {
	z-index: 100;
}