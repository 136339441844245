
.camera.mbp-picture-button {
	background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 8%;
	margin: 5% auto 0% auto;
	border-radius: 100px;
	outline: none;
	font-weight: 700;
	font-size: 20px;
	color: white;
}

.camera.mbp-cancel-picture-button {
   background: linear-gradient(180deg, #B61918 0%, #EE3124 100%);
   display: flex;
   justify-content: center;
   align-items: center;
   width: 80%;
   height: 8%;
   margin: 5% auto 0% auto;
   border-radius: 100px;
   outline: none;
   font-weight: 700;
   font-size: 20px;
   color: white;
}

.camera-switch {
	position: absolute;
	top: 15%;
	right: 20%;
	width: 42px;
    height: 42px;
    border-radius: 50px;
    background-color: #fff;
}

.camera_feed video {
	width: 69%;
}
.camera_feed .image_preview{
	width: 69%;
}

@media (orientation: portrait) {
	.camera_feed video {
		width: 100%;
	}
	.camera_feed .image_preview{
		width: 100%;
	}
}

@media ((max-width: 375px) and (orientation:landscape)){
	.camera_feed video {
		max-width: 65%;
		max-height: 50%;
	}
	.camera_feed .image_preview{
		max-width: 65%;
		max-height:50%;
	}
	.camera_feed{
		right: 22%;
	}
}