.centering {
    display: flex; justify-content: center; align-items: center;
}

.leaderboard-container {
    position: absolute; bottom: 0; height: 100%; width: 100%;
    display: flex; justify-content: center; align-items: center;
}
.leaderboard-inner-container {
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    width: 85%; height: 95%;
}

/* Character Image Section */
.l-image-container {
    display: flex; align-items: center;
    width: 100%; height: 20%;
}
.l-image-container img {
    height: 100%; max-height: 116px;
    margin-left: 18%; margin-right: 18%;
}
/* End of Character Image Section */

/* Points Total Section */
.l-points-container {
    display: flex; justify-content: center; align-items: center;
    height: 10%; width: 100%;
    font-weight: 900; font-size: 48px;
	/* line-height: 64px; */
}
/* End of Points Total Section */

/* Leaderboard Table Section */
.l-table-container {
    display: flex; justify-content: center; align-items: center;
    height: 50%; width: 115%; max-width: 100vw;
}
.lt-inner-container {
    display: flex; justify-content: center; align-items: center;
    height: 80%; width: 100%;
}
.l-table {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    width: 100%; height: 100%;
}
.l-table-row {
    display: flex; justify-content: center; align-items: center;
    width: 100%; height: 100%;
}
.l-table-row td {
    display: flex; justify-content: center; align-items: center;
    font-size: 25px; font-weight: 400;
	/* line-height: 30px; */
}
.ltr-position { width: 25%; height: 100%; }
.ltrp-inner-container {
    display: flex; width: 70%; height: 100%;
}
.ltrp-text{
    display: flex; justify-content: center; align-items: center;
    width: 40%; height: 100%;
}
.ltrp-image {
    display: flex; justify-content: center; align-items: center;
    width: 60%; height: 100%;
}
.ltrp-image img {
    width: 100%; max-width: 26px; max-height: 33px;
}
.ltr-username {
    width: 50%; height: 100%;
    border-left: 1px solid black; border-right: 1px solid black;
}
.ltr-score { width: 25%; height: 100%; }
/* End of Leaderboard Table Section */

/* Button Container */
.l-button-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    height: 20%; width: 100%;
}
.lb-button {
    display: flex; justify-content: center; align-items: center;
    height: 45%; border-radius: 30px;
    font-size: 22px; font-weight: 700;
	/* line-height: 26px;  */
	color: white;
}
.lb-recap-button {
    width: 90%; margin-bottom: 2.5%;
    max-width: 300px; max-height: 60px;
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
}
.lb-finish-button {
    width: 60%; margin-top: 2.5%;
    max-width: 130px; max-height: 60px;
    background: linear-gradient(180deg, #B71918 0%, #E42313 100%);
}
.lb-finish-recap-button {
    width: 60%; margin-bottom: 2.5%;
    max-width: 300px; max-height: 60px;
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
}

.lb-play-button {
    width: 70%; margin-top: 2.5%;
    max-height: 60px;
    background: linear-gradient(180deg, #B71918 0%, #E42313 100%);
}
/* End of Button Container */

/* Recap Popup */
.recap-popup-container {
    position: absolute; display: flex; justify-content: center; align-items: center;
    left: 0; right: 0; top: 0; bottom: 0; z-index: 999;
    width: 100%; height: 100%;
    background: rgba(0,0,0,0.6);
}
.rp-box-container {
    position: relative;
    display: flex; justify-content: center; align-items: center;
    width: 90%; height: 90%; border-radius: 12px;
    max-width: 344px; max-height: 697px;
    background: white;
}
.rpb-inner-container {
    position: relative; top: 2.5%;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    width: 90%; height: 85%;
}

.rpb-section {
    display: flex; justify-content: center; align-items: center; width: 100%;
}

/** Close Button */
.rpb-button {
    position: absolute; top: 3%; left: 5%;
    display: flex; justify-content: center; align-items: center;
    height: 5%;
}
.rpb-button img {
    height: 100%; width: 100%;
    max-height: 22px; max-width: 22px;
    min-height: 22px; min-width: 22px;
}
/** End of Close Button */

/** Title Section */
.rpb-title {
    height: 10%; margin-bottom: 5%;
    font-size: 24px; font-weight: 700;
	/* line-height: 32px; */
}
/** End of Title Section */

/** Description Section */
.rpb-description {
    height: 20%; text-align: center; margin-bottom: 5%;
    font-size: 22px; font-weight: 400;
	/* line-height: 30px; */
}
/** End of Description Section */

/** Subtitle Section */
.rpb-subtitle {
    height: 10%; text-align: center; margin-bottom: 5%;
    font-size: 22px; font-weight: 400;
	/* line-height: 30px; */
}
/** End of Subtitle Section */

/** Image Section */
.rpb-image {
    height: 30%; margin-bottom: 10%;
}
.rpb-image img {
    height: 100%; max-height: 158px;
}
/** End of Image Section */

/** Button Section */
.rpb-button-container {
    height: 15%;
}
.rpb-ok-button {
    display: flex; justify-content: center; align-items: center;
    width: 50%; height: 100%;
    max-width: 130px; max-height: 60px; border-radius: 30px;
    font-weight: 700; font-size: 22px;
	/* line-height: 26px;  */
	color: white;
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
}
/** End of Button Section */

/* End of Recap Popup */

/* Email Certificate Popup */

/** Email Input */
.rpb-email-container {
    display: flex; flex-direction: column; align-items: center;
    height: 20%;
}
.rpb-email-container label {
    height: 30%; width: 100%;
    font-size: 22px; font-weight: 400;
	/* line-height: 26px; */
}
.rpbe-input {
    display: flex; justify-content: center; align-items: center;
    width: 100%; height: 70%;
}
.rpbe-input input {
    width: 100%; height: 100%;
    max-width: 310px; max-height: 51px; padding: 0 2rem;
}
/** End of Email Input */

/** Email Description */
.rpb-email-description {
    height: 10%; margin-bottom: 10%;
    font-size: 15px;
	/* line-height: 18px;  */
	font-weight: 400;
}
/** End of Email Description */

/** Button Section */
.rpb-buttons-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    height: 30%;
}
.rpbb-button {
    display: flex; justify-content: center; align-items: center;
    width: 70%; height: 45%;
    max-width: 130px; max-height: 60px;
    border-radius: 30px; font-size: 22px; font-weight: 700;
    color: white;
	/* line-height: 26px; */
}
.rpb-send-button {
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
    margin-bottom: 2.5%;
}
.rpb-no-button {
    background: linear-gradient(180deg, #B71918 0%, #E42313 100%);
    margin-top: 2.5%;
}
/** End of Button Section */

/* End of Email Certificate Popup */

/* Email Confirmation Container */
.rpbc-inner-container {
    position: relative; top: 2.5%;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    width: 90%; height: 70%;
    max-height: 391px;
}
.rpbc-title {
    height: 30%; text-align: center;
    font-size: 24px; font-weight: 700;
	/* line-height: 32px; */
}
.rpbc-description {
    height: 30%; text-align: center;
    font-size: 22px; font-weight: 400;
	/* line-height: 26px; */
}
.rpbc-button { height: 40%; }
.rpbc-button a {
    display: flex; justify-content: center; align-items: center;
    height: 100%; width: 70%; border-radius: 32px;
    max-width: 130px; max-height: 60px;
    font-size: 22px; font-weight: 700;
	/* line-height: 26px;  */
	color: white;
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
}
/* End of Email Confirmation Container */
@media only screen and (max-width: 480px) {
    .class-small .rpb-title ,.class-small .rpb-ok-button{
        font-size: 16px;
    }
    .class-medium .rpb-title,  .class-medium .rpb-ok-button{
        font-size: 18px;
    }
    .class-large .rpb-title,.class-large .rpb-ok-button{
        font-size: 20px;
    }

    .class-small .rpb-description,.class-small .rpb-subtitle {
        font-size: 14px;
    }
    .class-medium .rpb-description, .class-medium .rpb-subtitle{
        font-size: 16px;
    }
    .class-large .rpb-description, .class-large .rpb-subtitle{
        font-size: 18px;
    }



  }

  @media only screen and (max-width: 768px) and (min-width: 481px) {
    .class-small .rpb-title, .class-small .rpb-ok-button {
        font-size: 18px;
    }
    .class-medium .rpb-title,  .class-medium .rpb-ok-button{
        font-size: 20px;
    }
    .class-large .rpb-title,.class-large .rpb-ok-button {
        font-size: 22px;
    }

    .class-small .rpb-description ,.class-small .rpb-subtitle {
        font-size: 16px;
    }
    .class-medium .rpb-description, .class-medium .rpb-subtitle{
        font-size: 18px;
    }
    .class-large .rpb-description, .class-large .rpb-subtitle {
        font-size: 20px;
    }


  }
  @media only screen and (max-width: 1024px) and (min-width: 769px) {
    .class-small .rpb-title, .class-small .rpb-ok-button{
        font-size: 20px;
    }
    .class-medium .rpb-title,  .class-medium .rpb-ok-button{
        font-size: 22px;
    }
    .class-large .rpb-title,.class-large .rpb-ok-button{
        font-size: 24px;
    }

    .class-small .rpb-description ,.class-small .rpb-subtitle {
        font-size: 18px;
    }
    .class-medium .rpb-description, .class-medium .rpb-subtitle {
        font-size: 20px;
    }
    .class-large .rpb-description, .class-large .rpb-subtitle {
        font-size: 22px;
    }

  }