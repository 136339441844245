.google-map {
    width: 100vw;
    height: 100vh;
    position: fixed; left: 0; top: 0;
}

.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: blue;
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 80vh;
    }

    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 10vw;
    }
}


/* Info Window */
.map-button-popup {
    display: flex; justify-content: center; align-items: center;
    position: absolute; bottom: 0; left: 0; right: 0; top: 0;
    width: 100%; height: 100%; z-index: 99;
    /*background: rgba(0,0,0,0.6);*/
}
.m-b-p-main-container {
    position: relative;
    width: 90%; height: 85%;
    background: white; border-radius: 12px;
}

/** Close Button */
.mbp-close-container {
    position: absolute; left: 5%; top: 3%; z-index: 999;
    background: none;
}

/* End of Info Window */

/** Quote Bubble */
.mbp-bubble-container {
    width: 115%; position: absolute; padding:20px 0px; left:0; top: 0;
}
.mbp-bubble-container img { width: 80%; }

.mbpb-title-container {
    position: absolute; top: 20%; left: 20%;
    width: 60%; height: 35%;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
}
.mbpb-title {
    display: flex; align-items: center;
    width: 100%; height: 25%;
    font-weight: 700; font-size: 24px;
	/* line-height: 32px; */
    text-transform: uppercase ;
}
.mbpb-description {
    display: flex; align-items: center;
    width: 100%; height: 75%;
    font-weight: 400; font-size: 20px;
    text-transform: none;
}
/** End of Quote Bubble */

/** Button Container */
.mbp-button-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    position: absolute; bottom: 3%; left: 2%;
    width: 50%; height: 30%; z-index: 999;
}
.mbp-button {
    display: flex; justify-content: center; align-items: center;
    width: 80%; height: 30%; margin: 5% 0;
    border-radius: 100px; outline: none;
    font-weight: 700; font-size: 20px; color: white;
}
.mbp-play-button {
    background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%);
    text-transform: uppercase;
}
.mbp-cancel-button {
    background: linear-gradient(180deg, #B61918 0%, #EE3124 100%);
}
/** End of Button Container */

/** Character Container */
.mbp-character-container {
    position: absolute; left: 35%; top: 50%;
    height: 60%;
}
.mbp-character-container img {
    height: 90%;
}
/** End of Character Container */

.gm-style .gm-style-iw-d {
    border: 2px solid blue;
}
.gm-style .gm-style-iw-c {
    position: absolute; width: 90vw; height: 90vh;
    left: 0; bottom: 0;
    display: flex; justify-content: center; align-items: center;
    transform: rotate(-48.25deg) translateY(-150px) translateX(-50px);
    border: 2px solid red !important;
}



.map-quiz-button {
    display: flex; justify-content: center; align-items: center;
}
.map-basic-btn {
    width: fit-content; height: fit-content;
    padding: 2rem 3rem; margin: 0 auto;

    background: linear-gradient(180deg, rgba(0, 92, 169, 0.94) 0%, #1488CA 100%);
    border-radius: 50px;border: none;
    text-decoration: none; color: white;
}
.map-quiz-btn {
    display: flex;
    padding: 2rem 3rem; margin: 2rem;
}

.map-section-container {
    position: relative;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    width: 100vw; height: 100%;
}

/* Header Section */
.w-m-header-container {
    display: flex; justify-content: center; align-items: center;
    height: 25%; width: 100%; position: relative;
    z-index: 99; margin-bottom: auto;
}

/** Image Section */
.w-m-h-image {
    position: absolute;
    width: 100%;
}
.w-m-h-image img {
    height: 100%; width: 100%;
    max-height: 100%; max-width: 100%;
    min-height: 100%; min-width: 100%;
	position:relative;
	top:-15px;
}
/** End of Image Section */

/** Header Contents */
.w-m-h-inner-container {
    position: relative; display: flex;
    justify-content: space-evenly;
    width: 100%; height: 90%;
}

.w-m-h-character {
    display: flex; justify-content: space-evenly; align-items: center;
    height: 90%; width: 30%;
    background: none;
    margin-top: 3%;
}
.w-m-h-character img {
    width: 70%; max-height: 100%;
}

/*** Score Section */
.w-m-h-score-container {
    display: flex; flex-direction: column;
    justify-content: center;
    width: 40%;
}

.wmhs-title {
    display: flex; align-items: center;
    width: 95%; height: 25%;
    font-weight: 700; font-size: 20px;
	/* line-height: 24px; */
    color: white;
	margin-bottom:1rem;
}
.wmhs-bar {
    display: flex; align-items: center; margin-top: 5%;
    height: 8%; border-radius: 1000px;
    background: white;
    border: 1px solid #005CA9;
}
/*** End of Score Section */


.w-m-h-settings {
    display: flex; justify-content: center; align-items: center;
    width: 17%;
    margin-top:3%;
}
.w-m-h-settings img{
    max-height: 100%;
}
/** End of Header Contents */

/* End of Header Section */

.wrapped-map-inner-container {
    position: absolute;
    width: 100%; height: 100%;

    border: 2px solid blue;
}

.map-element-container {
    position: relative;
    height: 250%; width: 250%;
    left: -75%; top: -75%;
    transform: rotate(48.25deg);
}

/* Locked Popup Container */
.locked-popup-container {
    display: flex; justify-content: center; align-items: center;
    position: absolute; bottom: 0; top: 0; left: 0; right: 0;
    width: 100vw; height: 100vh; z-index: 9999;
    background: rgba(0,0,0,0.6);
}
.lp-box {
    position: relative;
    display: flex; justify-content: center; align-items: center;
    width: 90%; height: 90%;
    background: white; border-radius: 12px;
}
.lp-image {
    display: flex; justify-content: center; align-items: center;
    z-index: 99;
    width: 50%;
}
.lp-image img { height: 100%; }
/* End of Locked Popup Container */


/* End Game Popup */
.end-adventure-container {
    position: absolute; display: flex;
    justify-content: center; align-items: center;
    left: 0; right: 0; top: 0; bottom: 0;
    width: 100vw; height: 100vh;
    background: rgba(0,0,0,0.6); z-index: 999;
}
.e-a-box-container {
    display: flex; justify-content: center; align-items: center;
    width: 90%; height: 50%;
    max-width: 344px; max-height: 319px;
    background: white; border-radius: 12px;
}
.e-a-b-inner-container {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    width: 90%; height: 90%;
}

/** Close Button */
.e-a-b-close {
    display: flex; justify-content: flex-start; align-items: center;
    height: 10%; width: 100%; margin-bottom: 10%;
}
.e-a-b-close img { height: 100%; max-height: 22px; max-width: 22px; }
/** End of Close Button */

/** Title Section */
.e-a-b-title {
    display: flex; justify-content: center; align-items: center;
    text-align: center; height: 20%; width: 90%;
    font-size: 24px;
	/* line-height: 32px;  */
	font-weight: 700;
}
/** End of Title Section */

/** Subtitle Section */
.e-a-b-subtitle {
    display: flex; justify-content: center; align-items: center;
    height: 15%; width: 90%; margin-bottom: 10%;
    font-size: 22px;
	/* line-height: 30px;  */
	font-weight: 400;
}
/** End of Subtitle Section */

/** Buttons Section */
.e-a-b-button-container {
    display: flex; justify-content: space-between; align-items: center;
    width: 80%; height: 25%; margin-bottom: 10%;
}
.eab-button {
    display: flex; justify-content: center; align-items: center;
    width: 45%; height: 100%;
    max-height: 60px; max-width: 100px;
    border-radius: 30px; color: white;
    font-weight: 700; font-size: 22px;
}
.eab-yes-button { background: linear-gradient(180deg, #B61918 0%, #EE3124 100%); }
.eab-no-button { background: linear-gradient(180deg, rgba(0, 87, 159, 0.94) 0%, #1488CA 100%); }
/** End of Buttons Section */
/* End of End Game Popup */
/*progress bar */
progress {
    width: 100%;
    border: 0;
}

progress[value]::-webkit-progress-bar {
    width: 100%;
    background: white;
    border-radius: 20px;
}

progress[value]::-moz-progress-bar { /* Firefox */
    margin-top: 2px;
    border-radius: 20px 0px 0px 20px;
    margin-left: 0px;
    background-color: #E42313

}

progress[value]::-webkit-progress-value { /* Chrome */
    margin-top: 2px;
    border-radius: 20px 0px 0px 20px;
    margin-left: 0px;
    background-color: #E42313
}
/* end progress bar */